<template>
  <v-content>
    <v-fade-transition mode="out-in">
      <router-view :key="store.state.language" />
    </v-fade-transition>
  </v-content>
</template>

<script>
  import store from '@/store/index.js'
  export default {
    name: 'BaseView',
    data: function () {
      return {
        store,
      }
    },
  }
</script>
